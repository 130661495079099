import React, { useState, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ContactForm from './ContactForm';
import emailjs from '@emailjs/browser';
import { Toast } from 'react-bootstrap';


function LandingPage() {
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  // CSS for 'Coming Soon' image with media queries
  const comingSoonStyle = {
    alignSelf: 'center',
    marginBottom: '10vh',
    width: 'auto', 
    maxWidth: '100%', 
  };

  // Media query styles
  const responsiveStyles = {
    // For tablets and smaller devices
    '@media (max-width: 768px)': { 
      comingSoonStyle: {
        marginBottom: '5vh', 
        maxWidth: '80%', 
      },
    },
    // For mobile phones
    '@media (max-width: 480px)': { 
      comingSoonStyle: {
        marginBottom: '2vh', 
        maxWidth: '70%', 
      },
    }
  };

  // Combine the styles
  const finalComingSoonStyle = {
    ...comingSoonStyle,
    ...responsiveStyles['@media (max-width: 768px)'].comingSoonStyle,
    ...responsiveStyles['@media (max-width: 480px)'].comingSoonStyle,
  };

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleToastClose = () => setShowToast(false);
  const handleToastShow = (message) => {
    setToastMessage(message);
    setShowToast(true);
  };

  const handleSubmit = () => {
    setLoading(true);
    emailjs.sendForm(
      'service_psnz7n4', // EmailJS service ID
      'template_un8vfks', // EmailJS template ID
      formRef.current,
      'YX2CifuLX7TRAegqB' // EmailJS public key
    ).then(
      (result) => {
        setLoading(false);
        handleToastShow('Message sent successfully!'); 
        handleClose();
        setForm({ name: '', email: '', message: '' }); 
      },
      (error) => {
        setLoading(false);
        handleToastShow('Failed to send message, please try again.'); // Use toast instead of alert
        console.error('Failed to send message:', error);
      }
    );
  };

  return (
    <div style={{
      width: '100vw',
      height: '100vh',
      background: `url('/Home.svg') no-repeat center center`,
      backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      {/* Toast Container */}
      <Toast onClose={handleToastClose} show={showToast} delay={3000} autohide
        style={{ 
          position: 'absolute', 
          bottom: '20px', 
          right: '20px', 
          zIndex: 1000  
        }}>
        <Toast.Header>
          <strong className="me-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
      {/* Logo and Coming Soon */}
      <div style={{ alignSelf: 'flex-start', position: 'absolute', top: '20px', left: '20px' }}>
        <img src="/Logo.svg" alt="Logo" />
      </div>
      <div style={finalComingSoonStyle}>
        <img src="/ComingSoon.svg" alt="Coming Soon" style={{ width: '100%' }} />
      </div>
      {/* Get Started button */}
      <img
        src="/GetStarted.svg"
        alt="Get Started"
        onClick={handleOpen}
        style={{ cursor: 'pointer', marginBottom: '20vh' }}
      />
      {/* Bootstrap Modal */}
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Sign Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContactForm form={form} handleChange={handleChange} formRef={formRef} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} style={{ width: '100px' }}>
            Close
          </Button>
          <Button variant="warning" onClick={handleSubmit} disabled={loading} style={{ width: '100px' }}>
            {loading ? 'Sending...' : 'Send'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LandingPage;
