import React from 'react';
import { Form, Button } from 'react-bootstrap';

const ContactForm = ({ form, handleChange, formRef }) => {
  return (
    <Form ref={formRef} style={{ padding: '20px', borderRadius: '8px', backgroundColor: '#FFFFFF' }}>
      <Form.Group className="mb-3">
        <Form.Label>Name:</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={form.name}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Email:</Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={form.email}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Message:</Form.Label>
        <Form.Control
          as="textarea"
          rows={4}
          name="message"
          value={form.message}
          onChange={handleChange}
        />
      </Form.Group>
    </Form>
  );
};

export default ContactForm;
